<template>
	<div>
		<CCard class="p-4">
			<CRow>

				<CCol lg="6">
					<h5> Work Orders ({{ count }})</h5>
				</CCol>
				<CCol lg="6">
					<CButton size="sm" style="float: right;"
						:class="'float-lg-right block ' + showCollapse ? 'collapsed' : null"
						:aria-expanded="showCollapse ? 'true' : 'false'" aria-controls="collapse1"
						@click="showCollapse = !showCollapse" color="info"><i class="fa fa-search"></i>Filter<i
							:class="showCollapse ? 'fa fa-caret-up' : 'fa fa-caret-down'"></i></CButton>
					<CButton size="sm" style="margin-right: 10px" color="success" target="_blank "
						href="#/main/create_work_order" class="float-lg-right"><i class="fa fa-plus"></i>Create Work Order
					</CButton>
					<!-- //for trucking company type reports// -->
					<CButton 
					 		v-if="is_trucking()"
							size="sm" style="margin-right: 10px" 
							color="btn btn-outline-primary"
							class="float-lg-right"
							@click="() => { showModalDownloadFilter = true }">
							<i class="fa fa-download"></i> Download
					</CButton>

					<!-- <CButton v-if="is_trucking()" size="sm" style="margin-right: 10px" color="btn btn-outline-primary"
						@click="downloadTrucking" class="float-lg-right"><i class="fa fa-download"></i>Download
					</CButton> -->
				</CCol>
			</CRow>
			<CRow>
				<CCol lg="12">
					<CCollapse id="collapse1" :show="showCollapse" class="mt-2">
						<CCard class="p-4">
							<Search @depot-search-query="search" :status="selected_status" />
						</CCard>
					</CCollapse>
				</CCol>
			</CRow>
			<hr>

			<CRow>
				<CCol lg="12">
					<div class="table-responsive-sm table-responsive-md">
						<CTabs variant="pills" :active-tab="0">
							<CTab title="All" @click.native="tabChanged($event, 'all')">
								<br />
								<CDataTable :loading="all_isloading" :items="dataList.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{ item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem @click="openApproveModal(item)"
													v-if="item.status == 'submitted'"><i class="fa fa-thumbs-o-up"> Approve
													</i></CDropdownItem>
												<CDropdownItem @click="openOnServiceModal(item)"
													v-if="item.status == 'approved'"><i class="fa fa-spinner"> On Service
													</i></CDropdownItem>
												<CDropdownItem @click="openCompleteModal(item)"
													v-if="item.status == 'on service'"><i class="fa fa-check-circle">
														Complete </i></CDropdownItem>
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPage" :pages="Math.ceil(dataList.total / 20)"
									:activePage="currentPage" @update:activePage="updatePage" />
							</CTab>
							<CTab title="Draft" @click.native="tabChanged($event, 'draft')">
								<br/>
								<CDataTable :loading="all_isloading" :items="dataListDraft.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover small outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">

										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageDraft"
									:pages="Math.ceil(dataListDraft.total / 20)" :activePage="currentPageDraft"
									@update:activePage="updatePageDraft" />
							</CTab>
							<CTab title="Submitted" @click.native="tabChanged($event, 'submitted')">
								<br />

								<CDataTable :loading="all_isloading" :items="dataListSubmitted.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{
												item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem @click="openApproveModal(item)"><i class="fa fa-thumbs-o-up">
														Approve </i></CDropdownItem>
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>


											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageSubmitted"
									:pages="Math.ceil(dataListSubmitted.total / 20)" :activePage="currentPageSubmitted"
									@update:activePage="updatePageSubmitted" />
							</CTab>
							<CTab title="Approved" @click.native="tabChanged($event, 'approved')">
								<br />
								<CDataTable :loading="all_isloading" :items="dataListApproved.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{
												item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">

												<CDropdownItem @click="openOnServiceModal(item)"><i class="fa fa-spinner">
														On Service </i></CDropdownItem>
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>

											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageApproved"
									:pages="Math.ceil(dataListApproved.total / 20)" :activePage="currentPageApproved"
									@update:activePage="updatePageApproved" />
							</CTab>


							<CTab title="On Service" @click.native="tabChanged($event, 'on service')">
								<br />

								<CDataTable :loading="all_isloading" :items="dataListOnService.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{
												item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem @click="openCompleteModal(item)"><i
														class="fa fa-check-circle"> Complete </i></CDropdownItem>
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageOnService"
									:pages="Math.ceil(dataListOnService.total / 20)" :activePage="currentPageOnService"
									@update:activePage="updatePageOnService" />
							</CTab>
							<CTab title="Completed" @click.native="tabChanged($event, 'completed')">
								<br />

								<CDataTable :loading="all_isloading" :items="dataListCompleted.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{
												item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">

												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageCompleted"
									:pages="Math.ceil(dataListCompleted.total / 20)" :activePage="currentPageCompleted"
									@update:activePage="updatePageCompleted" />
							</CTab>
							<CTab title="Cancelled" @click.native="tabChanged($event, 'cancelled')">
								<br />
								<CDataTable :loading="all_isloading" :items="dataListCancelled.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{
												item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageCancelled"
									:pages="Math.ceil(dataListCancelled.total / 20)" :activePage="currentPageCancelled"
									@update:activePage="updatePageCancelled" />
							</CTab>
							<CTab title="Declined" @click.native="tabChanged($event, 'declined')">
								<br />

								<CDataTable :loading="all_isloading" :items="dataListDeclined.data"
									:fields="company_type == 'trucking' ? fields_trucking : fields" hover outlined>

									<template #total_cost="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											{{
												item.labor_amount | computeTotal(item.work_order_part_request) | CurrencyFormatWithSign }}
										</td>
									</template>
									<template #status="{ item }">
										<td style="padding: 5px; width: 100px; text-transform:uppercase;">
											<CBadge style="margin-top:10px" :color="getBadge(item.status)">{{ item.status }}
											</CBadge>
										</td>
									</template>
									<template #action="{ item }">
										<td style="padding: 5px; width: 100px">
											<CDropdown toggler-text="Select" size="sm" color="info">
												<CDropdownItem @click="viewModal(item)"
													v-if="config.getPermission('work_order').view"><i class="fa fa-eye">
														View </i></CDropdownItem>
												<CDropdownItem @click="updateModal(item)"
													v-if="config.getPermission('work_order').update"><i
														class="fa fa-pencil-square-o"> Update </i></CDropdownItem>
												<CDropdownItem v-if="item.status !== 'cancelled'"
													@click="cancelBooking(item)"><i class="fa fa-times"> Cancel </i>
												</CDropdownItem>
												<CDropdownItem @click="confirmDelete(item)"
													v-if="config.getPermission('work_order').delete"><i class="fa fa-trash">
														Delete </i></CDropdownItem>
												<CDropdownItem @click="openHistoryModal(item)"><i class="fa fa-list-alt">
														View History </i></CDropdownItem>
											</CDropdown>
										</td>
									</template>
								</CDataTable>
								<CPagination :active-page.sync="currentPageDeclined"
									:pages="Math.ceil(dataListDeclined.total / 20)" :activePage="currentPageDeclined"
									@update:activePage="updatePageDeclined" />
							</CTab>
						</CTabs>
					</div>
				</CCol>
			</CRow>
			<CModal title="History" :show.sync="historyModal" size="lg" color="info">

				<CRow class="mb-1">
					<CCol lg="12">
						<CDataTable :items="historyList" :fields="[
							{
								key: 'description',
								label: 'Description'
							},
							{
								key: 'user_label',
								label: 'User'
							},
							{
								key: 'wo_date_created',
								label: 'Transaction Date'
							},
							{
								key: 'remarks_label',
								label: 'Remarks'
							}
						]" hover striped outlined>
							<template #description="{ item }">
								<td style="padding: 5px;">
									<span>{{ item.description }}</span><br />
									<small><span>{{ moment.unix(item.wo_date).format('MMMM DD, YYYY hh:mm A')}}</span></small>
								</td>
							</template>
							<template #action="{ item }">
								<td style="padding: 5px; width: 100px">
									<CDropdown toggler-text="Select" size="sm" color="info">
										<CDropdownItem @click="updatePurchasing(item)">Edit</CDropdownItem>
										<CDropdownItem @click="deleteRow(item)">Delete</CDropdownItem>
									</CDropdown>
								</td>
							</template>
						</CDataTable>
					</CCol>
				</CRow>

				<!-- <div slot="footer" class="w-100">
            	<CButton @click="historyModal = false" style="border-radius: .2rem; color: white;" color="dark" class="ml-1 mr-1 float-right">
                    Cancel
                </CButton>
                <CButton type="submit" style="border-radius: .2rem; color: white;" color="info" class="ml-1 mr-1 float-right">
                    Okay
                </CButton>
                
            </div>  -->
				<div slot="footer" class="w-100"></div>
			</CModal>
			<ApproveModal :details="selectedRow" @showModal="approveModal = $event; reloadData();"
				:showModal="approveModal" />
			<OnServiceModal :details="selectedRow" @showModal="onserviceModal = $event; reloadData();"
				:showModal="onserviceModal" />
			<CompleteModal :details="selectedRow" @showModal="completeModal = $event; reloadData();"
				:showModal="completeModal" />
		</CCard>
		<download-filter :filters="filter" :showModalDownloadFilter="showModalDownloadFilter" @closeModalDownloadFilter="closeModalDownloadFilter"/>
	</div>
</template>
<script>
import config from '../config.js';
import axios from '../axios';
import Swal from 'sweetalert2';
import Search from './search'
import Datepicker from 'vuejs-datepicker';
import vSelect from 'vue-select'
import 'vue-select/dist/vue-select.css';
import ApproveModal from './status_modal/approve';
import OnServiceModal from './status_modal/onservice';
import CompleteModal from './status_modal/complete';
import moment from 'moment';
import '../style.css';
import downloadFilter from '../reports/work_order_report_filter.vue';

export default {
	mounted() {
		// this.getVendor();
		// this.getDepot();
		this.getData();
		// this.getDataDraft();
		// this.getDataSubmitted();
		// this.getDataApproved();
		// this.getDataDeclined();
		// this.getDataOnService(); 
		// this.getDataCompleted();
		// this.getDataCancelled();
		for (var i = 0; i < document.querySelectorAll('.nav-item').length; i++) {
			document.querySelectorAll('.nav-item')[i].onclick = () => {
				this.current_tab = document.querySelector(".nav-link.active").innerHTML;
				this.setCount();
			}
		}


	},
	data() {
		return {
			company_type: JSON.parse(window.localStorage.getItem("user_data")).company.company_type.includes("Trucking") ? "trucking" : JSON.parse(window.localStorage.getItem("user_data")).company.company_type,
			config,
			showModalDownloadFilter:false,
			all_isloading: false,
			approveModal: false,
			onserviceModal: false,
			deliverModal: false,
			completeModal: false,
			formModal: false,
			showViewModal: false,
			dataList: [],
			dataListDraft: [],
			dataListApproved: [],
			dataListSubmitted: [],
			dataListDeclined: [],
			dataListOnService: [],
			dataListDelivered: [],
			dataListCompleted: [],
			dataListCancelled: [],
			current_tab: "All",
			count: 0,
			historyList: [],


			depotName: "",
			fields: [
				{
					key: 'wo_no',
					label: 'WO #'
				},
				{
					key: 'date_needed_label',
					label: 'Date Needed'
				},
				{
					key: 'asset_type',
					label: 'Asset Type'
				},
				{
					key: 'wo_type',
					label: 'WO Type'
				},
				{
					key: 'dept_id_label',
					label: 'Proj/Dept'
				},
				{
					key: 'depot_id_label',
					label: 'Depot'
				},

				{
					key: 'asset_no_label',
					label: 'Asset No.'
				},

				// {
				// 	key: 'srf_no_label', 
				// 	label: 'SRF No.'
				// },
				{
					key: 'plate_no',
					label: 'Plate No.'
				},
				{
					key: 'service_summary_label',
					label: 'Service Summary'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}
			],
			fields_trucking: [
				{
					key: 'wo_no',
					label: 'WO #'
				},
				{
					key: 'date_needed_label',
					label: 'Request Date'
				},

				{
					key: 'depot_id_label',
					label: 'Depot'
				},

				{
					key: 'plate_no',
					label: 'Plate No.'
				},
				{
					key: 'scope_of_work',
					label: 'Scope of Work'
				},
				{
					key: 'total_cost',
					label: 'Total Cost'
				},
				{
					key: 'status',
					label: 'Status'
				},
				{
					key: 'action',
					label: 'Action',
					_style: { width: '20px' }
				}
			],
			currentPage: 1,
			currentPageDraft: 1,
			currentPageApproved: 1,
			currentPageSubmitted: 1,
			currentPageDeclined: 1,
			currentPageOnService: 1,
			currentPageDelivered: 1,
			currentPageCompleted: 1,
			currentPageCancelled: 1,


			editMode: false,
			selectedRow: {},
			showCollapse: false,
			filter: {
				wo_no: "",
				date_needed_from: "",
				date_needed_to: "",
				wo_type: "",
				asset_type: "",
				asset_no: "",
				srf_no: "",
				dept_id: "",
				depot_id: "",
				status: 'all'
			},
			vendorList: {
				data: []
			},
			depotList: {
				data: []
			},
			historyModal: false,
			moment,
			selected_status: 'all'
		}
	},
	name: 'Tables',
	// components: {Search, Datepicker, vSelect, ApproveModal, OnServiceModal, DeliverModal, CompleteModal},
	components: { Datepicker, vSelect, ApproveModal, OnServiceModal, CompleteModal, Search, downloadFilter },
	methods: {
		tabChanged(ev, type) {
			if (ev.target.className != 'nav-link active') return;
			this.selected_status = type;
			this.filter.status = type;
			this.getData(type)
		},
		closeModalDownloadFilter(status){
			this.showModalDownloadFilter = status;
		},
		
		setCount() {
			if (this.current_tab == "All") {
				this.count = this.dataList.total
			}
			if (this.current_tab == "Draft") {
				this.count = this.dataListDraft.total
			}
			if (this.current_tab == "Submitted") {
				this.count = this.dataListSubmitted.total
			}
			if (this.current_tab == "Approved") {
				this.count = this.dataListApproved.total
			}
			if (this.current_tab == "Declined") {
				this.count = this.dataListDeclined.total
			}
			if (this.current_tab == "On Service") {
				this.count = this.dataListOnService.total
			}
			if (this.current_tab == "Delivered") {
				this.count = this.dataListDelivered.total
			}
			if (this.current_tab == "Completed") {
				this.count = this.dataListCompleted.total
			}
			if (this.current_tab == "Cancelled") {
				this.count = this.dataListCancelled.total
			}
		},
		reloadData() {
			this.getData();
			// this.getDataDraft();
			// this.getDataSubmitted();
			// this.getDataApproved();
			// this.getDataDeclined();
			// this.getDataOnService(); 
			// this.getDataCompleted();
			// this.getDataCancelled();
		},
		getBadge(status) {
			return status === 'completed' ? 'success'
				: status === 'draft' ? 'dark'
					: status === 'on service' ? 'warning'
						: status === 'cancelled' ? 'secondary'
							: status === 'approved' ? 'primary'
								: status === 'submitted' ? 'info'
									: status === 'declined' ? 'danger' : 'primary'
		},
		updatePage(data) {
			this.currentPage = data;
			this.getData('all');
		},

		updatePageDraft(data) {
			this.currentPageDraft = data;
			this.getData('draft');
			// this.getDataDraft();
		},

		updatePageApproved(data) {
			this.currentPageApproved = data;
			this.getData('approved');
			// this.getDataApproved();
		},
		updatePageSubmitted(data) {
			this.currentPageSubmitted = data;
			this.getData('submitted');
			// this.getDataSubmitted();
		},

		updatePageDeclined(data) {
			this.currentPageDeclined = data;
			this.getData('declined');
			// this.getDataDeclined();
		},

		updatePageOnService(data) {
			this.currentPageOnService = data;
			this.getData('on service');
			// this.getDataOnService();
		},

		updatePageDelivered(data) {
			this.currentPageDelivered = data;
			// this.getDataDelivered();
			this.getData('delivered');
		},

		updatePageCompleted(data) {
			this.currentPageCompleted = data;
			// this.getDataCompleted();
			this.getData('completed');
		},

		updatePageCancelled(data) {
			this.currentPageCancelled = data;
			this.getData('cancelled');
			// this.getDataCancelled();
		},

		toggleModal() {
			this.formModal = !this.formModal;
		},

		search(event) {
			this.filter = event;
			this.getData(this.current_tab.toLowerCase());
			// this.getDataDraft();
			// this.getDataSubmitted();
			// this.getDataApproved();
			// this.getDataDeclined();
			// this.getDataOnService(); 
			// this.getDataCompleted();
			// this.getDataCancelled();
		},

		getData(status = 'all') {

			this.all_isloading = true;

			// let status = [null,'draft','submitted','approved','on service','completed','cancelled','declined'];
			// if(status) {
			status = (status == 'all') ? null : status.toLowerCase();
			// }

			var date_needed_from = this.filter.date_needed_from;
			if (date_needed_from != "") {
				date_needed_from = moment(date_needed_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			}

			var date_needed_to = this.filter.date_needed_to;
			if (date_needed_to != "") {
				date_needed_to = moment(date_needed_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
				date_needed_to = date_needed_to + 86399;
			}

			var wo_type = this.filter.wo_type;
			if (wo_type == null) {
				wo_type = "";
			}

			var asset_type = this.filter.asset_type;
			if (asset_type == null) {
				asset_type = "";
			}

			var asset_no = this.filter.asset_no;
			if (asset_no == null) {
				asset_no = "";
			}

			var dept_id = this.filter.dept_id;
			if (dept_id == null) {
				dept_id = "";
			}

			var depot_id = this.filter.depot_id;
			if (depot_id == null) {
				depot_id = "";
			}

			let url = `${config.api_path}/workOrder`;
			let page = 1;

			switch (status) {
				case 'draft':
					page = this.currentPageDraft;
					break;
				case 'submitted':
					page = this.currentPageSubmitted;
					break;
				case 'approved':
					page = this.currentPageApproved;
					break;
				case 'on service':
					page = this.currentPageOnService;
					break;
				case 'completed':
					page = this.currentPageCompleted;
					break;
				case 'cancelled':
					page = this.currentPageCancelled;
					break;
				case 'declined':
					page = this.currentPageDeclined;
					break;
				default:
					page = this.currentPage;
					break;
			}

			let params = {
				status,
				page,
				limit: 20,
				wo_no: this.filter.wo_no,
				date_needed_from,
				date_needed_to,
				wo_type,
				depot_id,
				asset_type,
				asset_no,
				srf_no: this.filter.srf_no,
				dept_id
			}

			axios.get(url, { params }).then(response => {
				// this.dataList = response.data; 
				let local_data = response.data.data.map((value, index) => {
					// if(value.service_summary && value.service_summary != ""){
					// 	value.service_summary_label = value.service_summary
					// }
					// else{
					// 	value.service_summary_label = "-"
					// } 
					value.service_summary_label = value.service_summary ? this.$options.filters.truncate(value.service_summary) : ' ';
					value.scope_of_work = value.scope_of_work ? this.$options.filters.truncate(value.scope_of_work) : ' ';

					if (value.srf_no && value.srf_no != "") {
						value.srf_no_label = value.srf_no
					}
					else {
						value.srf_no_label = "-"
					}

					if (value.dept) {
						value.dept_id_label = value.dept.customer_name
					}
					else {
						value.dept_id_label = "-"
					}
					if (value.depot_id) {
						value.depot_id_label = value.depot?.setting_name ?? '';
					}
					else {
						value.depot_id_label = "-"
					}

					value.plate_no = '-';
					if (value.asset_type == "vehicle") {
						value.asset_no_label = value.vehicle?.asset_no ?? '';
						value.plate_no = value.vehicle?.plate_no ?? ' ';
					}
					else {
						if (value.furnishing_id) {
							value.asset_no_label = value.furnishing?.asset_no ?? '-';
						}
						else {
							value.asset_no_label = value.equipment?.asset_no ?? '-';
						}
					}
					if (value.date_needed) {
						value.date_needed_label = moment.unix(value.date_needed).format('MM/DD/YYYY')
					}
					else {
						value.date_needed_label = "-"
					}
					value.wo_type = value.wo_type?.toUpperCase() ?? '';
					value.asset_type = value.asset_type?.toUpperCase() ?? '';

					return value;
				});

				switch (status) {
					case 'draft':
						this.dataListDraft = response.data;
						this.dataListDraft.data = [...local_data];
						break;
					case 'submitted':
						this.dataListSubmitted = response.data;
						this.dataListSubmitted.data = [...local_data];
						break;
					case 'approved':
						this.dataListApproved = response.data;
						this.dataListApproved.data = [...local_data];
						break;
					case 'on service':
						this.dataListOnService = response.data;
						this.dataListOnService.data = [...local_data];
						break;
					case 'completed':
						this.dataListCompleted = response.data;
						this.dataListCompleted.data = [...local_data];
						break;
					case 'cancelled':
						this.dataListCancelled = response.data;
						this.dataListCancelled.data = [...local_data];
						break;
					case 'declined':
						this.dataListDeclined = response.data;
						this.dataListDeclined.data = [...local_data];
						break;
					default:
						this.dataList = response.data;
						this.dataList.data = [...local_data];
						break;
				}

				this.setCount()
			})
				.catch(err => {
					console.error(err)
					this.all_isloading = false;
				})
				.finally(fin => {

					this.all_isloading = false;
				})

			// axios.get(config.api_path+'/workOrder?page='+this.currentPage+'&limit=20&wo_no='+this.filter.wo_no+'&date_needed_from='+date_needed_from+'&date_needed_to='+date_needed_to+'&wo_type='+wo_type+'&asset_type='+asset_type+'&asset_no='+asset_no+'&srf_no='+this.filter.srf_no+'&dept_id='+dept_id)

		},
		viewModal(item) {
			if (this.is_trucking()) {
				this.$router.push('/main/view_work_order_trucking/' + item.id)
			} else {
				this.$router.push('/main/view_work_order/' + item.id)
			}
		},
		updateModal(item) {
			if (this.is_trucking()) {
				window.open('/#/main/update_work_order_trucking/' + item.id, '_blank');
			}
			else {
				window.open('/#/main/update_work_order/' + item.id, '_blank');
				this.changeAsset(item.vehicle_id);
			}
		},
		cancelBooking(item) {
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to cancel this work order?',
				showCancelButton: true,
				confirmButtonText: `Yes`,
			}).then((result) => {
				if (result.isConfirmed) {
					Swal.fire({
						title: 'Cancellation Reason',
						input: 'text',
						inputAttributes: {
							autocapitalize: 'off',
							required: true
						},
						showCancelButton: true,
						confirmButtonText: 'Save',
						showLoaderOnConfirm: true,
						preConfirm: (cancellation_reason) => {
							return axios.put(config.api_path + '/workOrder/' + item.id, {
								status: "cancelled",
								history_description: "Updated status to cancelled",
								history_wo_date: Math.floor(Date.now() / 1000),
								history_remarks: ""
							})
								.then(response => {

									if (response.status == "success") {
										throw new Error(response.statusText)
									}
									return response;
								})
								.catch(error => {
									Swal.showValidationMessage(
										`Request failed: ${error}`
									)
								})

						},
						allowOutsideClick: () => !Swal.all_isloading()
					}).then((result) => {
						if (result.isConfirmed) {
							this.getData();
							Swal.fire({
								title: 'Success!',
								text: "Work Order successfully cancelled.",
								icon: 'success',
							})
						}
					})

				}
			})
		},

		confirmDelete(item) {
			Swal.fire({
				icon: 'warning',
				title: 'Are you sure you want to delete work order ' + item.wo_no + "?",
				showCancelButton: true,
				confirmButtonText: `Yes`,
			}).then((result) => {
				if (result.isConfirmed) {

					axios.delete(config.api_path + '/workOrder/' + item.id)
						.then(response => {
							Swal.fire({
								title: 'Success!',
								text: "Work Order " + item.wo_no + " successfully deleted.",
								icon: 'success',
							})
							this.getData();
							// this.getDataDraft();
							// this.getDataSubmitted();
							// this.getDataApproved();
							// this.getDataDeclined();
							// this.getDataOnService(); 
							// this.getDataCompleted();
							// this.getDataCancelled();
						})

				}
			})
		},

		openApproveModal(item) {
			this.approveModal = true;
			this.selectedRow = item;
		},

		openOnServiceModal(item) {
			this.onserviceModal = true;
			this.selectedRow = item;
		},


		openCompleteModal(item) {
			this.completeModal = true;
			this.selectedRow = item;
		},


		// download() {
		// 	axios.get(config.api_path + '/reports/booking')
		// 		.then(response => {
		// 			window.open(config.main_path.replace('/index.php', '') + '/' + response.data.file)
		// 		})
		// },
		openHistoryModal(item) {
			this.historyModal = true;
			this.historyList = item.work_order_history.map((value, index) => {
				value.user_label = value.user.firstname + " " + value.user.lastname;

				if (value.remarks && value.remarks != "") {
					value.remarks_label = value.remarks;
				}
				else {
					value.remarks_label = "-";
				}
				value.wo_date_created = moment(value.created_at).format('MMMM DD, YYYY h:mm:ss A')
				return value
			});
			console.log(this.historyList)
		},
		downloadTrucking() {
			var date_needed_from = this.filter.date_needed_from;
			if (date_needed_from != "") {
				date_needed_from = moment(date_needed_from, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
			}

			var date_needed_to = this.filter.date_needed_to;
			if (date_needed_to != "") {
				date_needed_to = moment(date_needed_to, "MM-DD-YYYY").startOf('day').valueOf() / 1000;
				date_needed_to = date_needed_to + 86399;
			}

			var wo_type = this.filter.wo_type;
			if (wo_type == null) {
				wo_type = "";
			}

			var asset_type = this.filter.asset_type;
			if (asset_type == null) {
				asset_type = "";
			}

			var asset_no = this.filter.asset_no;
			if (asset_no == null) {
				asset_no = "";
			}

			var dept_id = this.filter.dept_id;
			if (dept_id == null) {
				dept_id = "";
			}

			var depot_id = this.filter.depot_id;
			if (depot_id == null) {
				depot_id = "";
			}

			let url = `${config.api_path}/reports/workOrder/trucking`;
			let page = 1;

			let params = {
				page,
				limit: 20,
				wo_no: this.filter.wo_no,
				date_needed_from,
				date_needed_to,
				wo_type,
				depot_id,
				asset_type,
				asset_no,
				srf_no: this.filter.srf_no,
				dept_id
			}
			this.$showLoading(true)
			axios.get(url, { params }).then(response => {
				this.$showLoading(false)
				window.open(config.main_path.replace('/index.php', '') + '/' + response.data.file)
				setTimeout(() => {
					axios.get(`${config.api_path}/remove-file`, {
						params: {
							filename: response.data.file
						}
					})
				}, 2000);
			})
				.catch(err => {
					this.$showLoading(false)
					console.error(err)
					this.all_isloading = false;
				})
		},
	}
}
</script>
